import React, { useContext, useEffect } from "react";
import Timer from "./Timer";
import { Store } from "../Context/Storage";

const Borker = (props) => {
  let { backgroundclr, containerclr, topBar } = useContext(Store)

  let { setactive } = props;
  useEffect(() => {
    setactive("broker");
  }, []);
  return (
    <div className="min-h-[100vh]" style={{backgroundColor:`${backgroundclr}`}} >
      <div style={{backgroundColor:`${topBar}`}} className="min-h-[10vh] flex justify-between items-center border-bottom px-6 sm:px-10 ">
        <p className="mb-0">Broker</p>
        <Timer />
      </div>
      {/* Broker Setup */}
      <div>
        <div className="pt-3 ps-2" style={{backgroundColor:`${containerclr}`}}>
        <h1 className="fw-semibold fs-5">Broker Setup</h1>
        <hr />
        </div>
        {/* brokers card */}
        <div className="container mx-auto flex flex-wrap gap-2 justify-around">
          {/* <div className="w-[13rem] flex flex-col justify-between  rounded mx-2 shadow p-3 ">
            <img src={require("../assest/Zerodha-Logo.png")} alt="" />
            <button className="bg-blue-800 flex items-center gap-2 shadow hover:bg-blue-700 mx-auto text-white rounded p-1 px-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                />
              </svg>{" "}
              <p className="mb-0">Setup</p>
            </button>
          </div> */}
          <div  className="w-[13rem] bg-white flex flex-col justify-between rounded mx-2 shadow p-3 ">
            <img src={require("../assest/angelone.avif")} alt="" />
            <button className="bg-blue-800 flex items-center gap-2 shadow hover:bg-blue-700 mx-auto text-white rounded p-1 px-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"
                />
              </svg>{" "}
              <p className="mb-0">Setup</p>
            </button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Borker;

import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import Navbar from './Navbar'
import { useNavigate } from 'react-router'
import StockIcon from './StockIcon'

const MobileNavbar = (props) => {
    let [show,setshow]=useState(false)
    let navigate=useNavigate()
    let {active}=props
  return (
    <div className='sticky-top d-lg-none '>
        <nav className="navbar navbar-expand-lg px-4 navbar-light bg-light">
        <div><img className='inline' src={require('../assest/algologo.png')} width={60} alt="logo" />
        <p className='inline font-serif fw-light mx-2 m-0'>CVSKPBAR</p></div>
      <button onClick={()=>setshow(true)} className="navbar-toggler" 
      type="button" data-toggle="collapse" 
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  </nav>
        <Offcanvas className='h-[100vh]' show={show} onHide={()=>setshow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>       
            <div>
            <img className='mt-3 inline' src={require('../assest/algologo.png')} width={60} alt="logo" /> <p className='inline font-serif fw-light m-0'>CVSKPBAR</p>
            </div>
</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className={`h-full flex flex-col justify-between`}>
          {/* Nav bar  */}
          
          <div className=' fw-semibold flex flex-col h-[15vh] justify-between '>
          <div  onClick={()=>{navigate('/welcome/');setshow(false)}} className={`flex  ${active==='dashboard'? 'text-blue-800':'text-slate-400'} transition duration-500 w-[180px] items-center cursor-pointer justify-around `}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-clipboard-data" viewBox="0 0 16 16">
  <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
</svg> <p className='mb-0 w-[90px] fw-semibold '>Dashboard</p>
            </div>
            <div onClick={()=>{navigate('/welcome/strategy');setshow(false)}} className={`flex  ${active==='strategy'? 'text-blue-800':'text-slate-400'} transition duration-500 w-[180px] items-center cursor-pointer justify-around `}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi -rotate-90 bi-body-text" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5m0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
</svg> <p className='mb-0 w-[90px]  ' >Strategy</p>
            </div>
            <div  onClick={()=>{navigate('/welcome/broker');setshow(false)}} className={`flex  ${active==='broker'? 'text-blue-800':'text-slate-400'} transition duration-500 w-[180px] items-center cursor-pointer justify-around `}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi  bi-journals" viewBox="0 0 16 16">
  <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2"/>
  <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0"/>
</svg><p className='mb-0 w-[90px]  ' >Broker</p>
            </div>
            <div  onClick={()=>{navigate('/welcome/results');setshow(false)}} className={`flex  ${active==='result'? 'text-blue-800':'text-slate-400'} transition duration-500 w-[180px] items-center cursor-pointer justify-around `}>
                       <StockIcon/> <p className='mb-0 w-[90px]  ' >Result</p>
            </div>

          </div>
          <div className='h-[10vh] flex flex-col justify-around ' >
         
            <div onClick={()=>{navigate('/welcome/settings');setshow(false)}} 
            className={`flex w-[180px] fw-semibold ${active==='settings'? 'text-blue-800':'text-slate-400'}  items-center cursor-pointer justify-around`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg> <p className='mb-0 w-[90px]  '>Settings</p>
            </div>
            <div onClick={()=>{
              navigate('/')
              sessionStorage.removeItem('user')
            }} className='flex w-[180px] fw-semibold text-slate-400 items-center cursor-pointer justify-around'>
           <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg> <p className='mb-0 w-[90px]  '>Log Out</p>
            </div>

          </div>
        
      </div>

        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default MobileNavbar
let data=[
    {
        "exchange": "NMS",
        "shortname": "Apple Inc.",
        "quoteType": "EQUITY",
        "symbol": "AAPL",
        "index": "quotes",
        "score": 65103,
        "typeDisp": "Equity",
        "longname": "Apple Inc.",
        "exchDisp": "NASDAQ",
        "sector": "Technology",
        "sectorDisp": "Technology",
        "industry": "Consumer Electronics",
        "industryDisp": "Consumer Electronics",
        "dispSecIndFlag": true,
        "isYahooFinance": true
    },
    {
        "exchange": "NYQ",
        "shortname": "Apple Hospitality REIT, Inc.",
        "quoteType": "EQUITY",
        "symbol": "APLE",
        "index": "quotes",
        "score": 20118,
        "typeDisp": "Equity",
        "longname": "Apple Hospitality REIT, Inc.",
        "exchDisp": "NYSE",
        "sector": "Real Estate",
        "sectorDisp": "Real Estate",
        "industry": "REIT—Hotel & Motel",
        "industryDisp": "REIT—Hotel & Motel",
        "isYahooFinance": true
    },
    {
        "exchange": "NEO",
        "shortname": "APPLE CDR (CAD HEDGED)",
        "quoteType": "EQUITY",
        "symbol": "AAPL.NE",
        "index": "quotes",
        "score": 20028,
        "typeDisp": "Equity",
        "longname": "Apple Inc.",
        "exchDisp": "NEO",
        "sector": "Technology",
        "sectorDisp": "Technology",
        "industry": "Consumer Electronics",
        "industryDisp": "Consumer Electronics",
        "isYahooFinance": true
    },
    {
        "exchange": "FRA",
        "shortname": "APPLE INC.",
        "quoteType": "EQUITY",
        "symbol": "APC.F",
        "index": "quotes",
        "score": 20025,
        "typeDisp": "Equity",
        "longname": "Apple Inc.",
        "exchDisp": "Frankfurt",
        "sector": "Technology",
        "sectorDisp": "Technology",
        "industry": "Consumer Electronics",
        "industryDisp": "Consumer Electronics",
        "isYahooFinance": true
    },
    {
        "exchange": "BUE",
        "shortname": "APPLE INC CEDEAR(REPR 1/20 SHR)",
        "quoteType": "EQUITY",
        "symbol": "AAPL.BA",
        "index": "quotes",
        "score": 20020,
        "typeDisp": "Equity",
        "longname": "Apple Inc.",
        "exchDisp": "Buenos Aires",
        "sector": "Technology",
        "sectorDisp": "Technology",
        "industry": "Consumer Electronics",
        "industryDisp": "Consumer Electronics",
        "isYahooFinance": true
    },
    {
        "exchange": "SAO",
        "shortname": "APPLE       DRN",
        "quoteType": "EQUITY",
        "symbol": "AAPL34.SA",
        "index": "quotes",
        "score": 20012,
        "typeDisp": "Equity",
        "longname": "Apple Inc.",
        "exchDisp": "São Paulo",
        "sector": "Technology",
        "sectorDisp": "Technology",
        "industry": "Consumer Electronics",
        "industryDisp": "Consumer Electronics",
        "isYahooFinance": true
    },
    {
        "exchange": "PNK",
        "shortname": "APPLE RUSH COMPANY INC",
        "quoteType": "EQUITY",
        "symbol": "APRU",
        "index": "quotes",
        "score": 20009,
        "typeDisp": "Equity",
        "longname": "Apple Rush Company, Inc.",
        "exchDisp": "OTC Markets",
        "sector": "Consumer Defensive",
        "sectorDisp": "Consumer Defensive",
        "industry": "Beverages—Non-Alcoholic",
        "industryDisp": "Beverages—Non—Alcoholic",
        "isYahooFinance": true
    }
]
export const arrydata=data.map((value)=>{
    return value.shortname
})
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { hostname } from '../App'
import { Store } from '../Context/Storage'

const Results = (props) => {
    let { backgroundclr, containerclr, topBar } = useContext(Store)
    let [company, setCompany] = useState()
    let [filterOptions, setFilterOptions] = useState({
        company_name: '',
        stock_type: '',
        user_id: JSON.parse(sessionStorage.getItem('user')),
        start_date: '',
        end_date: ''
    })
    let handleFilterOptions = (e) => {
        let { name, value } = e.target
        if (name == 'end_date' && filterOptions.start_date &&
            value < filterOptions.start_date)
            value = filterOptions.start_date
        if (name == 'start_date' && filterOptions.end_date &&
            value > filterOptions.end_date)
            value = filterOptions.end_date

        setFilterOptions((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const { setactive } = props
    let [resultDetails, setResultDetails] = useState()
    let getFullResultDetails = () => {
        axios.post(`${hostname}/getpl/`, {
            userid: JSON.parse(sessionStorage.getItem('user'))
        }).then((response) => {
            setResultDetails(response.data)
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    let getFilteredData = () => {
        if (filterOptions.company_name != '' || filterOptions.end_date != '' || filterOptions.stock_type != '' ||
            filterOptions.start_date != '')
            axios.post(`${hostname}/FilterForProfitandLoss/`, filterOptions).then((response) => {
                setResultDetails(response.data)
                console.log(response.data, "result");
            }).catch((error) => {
                console.log(error);
            })
        else
            getFullResultDetails()
    }
    let getCompany = () => {
        axios.get(`${hostname}/CompanyDetails`).then((response) => {
            console.log(response.data);
            setCompany(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        setactive("result")
        console.log(JSON.parse(sessionStorage.getItem('user')));
        getFullResultDetails()
        getCompany()
    }, [])
    return (
        <div style={{ backgroundColor: `${backgroundclr}` }} className='min-h-[100vh] py-3'>

            <p className='fw-semibold text-center'>Results of the Previous Orders </p>
            <section className='flex flex-wrap gap-4 container mx-auto my-3 ' >
                <div>
                    <label htmlFor="" className='fw-semibold text-lg ' > Company Name </label>
                    <select name="company_name" className=' mx-2 rounded outline-none p-2  '
                        onChange={handleFilterOptions}
                        value={filterOptions.company_name} id="">
                        <option value="">Select </option>
                        {
                            company && company.map((obj) => (
                                <option value={obj.company_name} > {obj.company_name} </option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <label htmlFor="" className='fw-semibold text-lg ' > Stock Type </label>
                    <select name="stock_type" className=' mx-2 rounded outline-none p-2  '
                        onChange={handleFilterOptions}
                        value={filterOptions.stock_type} id="">
                        <option value="">Select </option>
                        <option value="OPTSTK">OTPSTK </option>
                        <option value="FUTSTK">FUTSTK </option>
                        <option value="Equity">Equity </option>
                    </select>
                </div>
                <div>
                    <label htmlFor=" " className='fw-semibold text-lg ' >Start Date </label>
                    <input value={filterOptions.start_date} name='start_date' onChange={handleFilterOptions}
                        type="date" className=' rounded outline-none p-2 mx-2' />
                </div>
                <div>
                    <label htmlFor=" " className='fw-semibold text-lg ' >End Date </label>
                    <input value={filterOptions.end_date} name='end_date' onChange={handleFilterOptions}
                        type="date" className=' rounded outline-none p-2 mx-2' />
                </div>

                <button onClick={getFilteredData} className='p-2 bg-green-500 rounded text-white ms-auto' >
                    Search
                </button>
            </section>


            <main className='container mx-auto ' >
                <div className='table-responsive h-[75vh] 
            overflow-y-scroll container mx-auto  min-w-[800px]  '>
                    <table className='w-full '>
                        <tr className='bg-slate-200 sticky top-0 '>
                            <th className='border-1 w-[30px] p-2'>SI.NO </th>
                            <th className='border-1 p-2 text-center '>Company </th>
                            <th className='border-1 p-2 text-center'> Trading Symbol</th>
                            <th className='border-1 p-2 text-center'> Date / Time </th>
                            <th className='border-1 p-2 text-center'> Profit/Loss </th>
                        </tr>
                        {
                            resultDetails && [...resultDetails].reverse().map((x, index) => {
                                return (
                                    <tr className={`${index % 2 == 0 ? 'bg-slate-50' : 'bg-slate-100'} `} >
                                        <td className='border-1 w-[30px] p-2 text-center'>{index + 1} </td>
                                        <td className='border-1 p-2'>{x.company_name} </td>
                                        <td className='border-1 p-2'> {x.trading_symbol} </td>
                                        <td className='border-1 p-2'> {x.datetime} </td>
                                        <td className='border-1 p-2'> {x.profit_loss} </td>
                                    </tr>
                                )
                            })
                        }
                    </table>

                </div>
                <div className='flex    '>
                    <p className='ms-auto bg-white flex fw-semibold p-3 border-1'>Total :
                        {resultDetails && resultDetails.reduce((sum, item) => sum + Number(item.profit_loss), 0)} </p>
                </div>

            </main>
        </div>
    )
}

export default Results
import React from "react";
import { createRoot }from "react-dom/client"
import App from "./App";
import Storage from "./Context/Storage";
createRoot( document.getElementById('root')).render(
    <React.StrictMode>
        <Storage>
        <App/> 

        </Storage>
    </React.StrictMode>
)
import axios from 'axios'
import React, { Children, createContext, useEffect, useState } from 'react'
import { hostname } from '../App'


export const Store = createContext()
const Storage = (props) => {
    let [backgroundclr,setBackgroundClr]=useState('')
    let [navBarClr,setNavClr]=useState('')
    let [topBar,setTopBar]=useState('')
    let [containerclr,setcontainerclr]=useState('')
    let getColor=()=>{
        axios.post(`${hostname}/ThemeRetrive/`,{
             user_id:JSON.parse(sessionStorage.getItem('user'))
        }).then((response)=>{
            console.log(response.data);
            setNavClr(response.data.navbar)
            setTopBar(response.data.header)
            setcontainerclr(response.data.legs)
            setBackgroundClr(response.data.body)
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        if(JSON.parse(sessionStorage.getItem('user'))){
        getColor()
    }

    },[JSON.parse(sessionStorage.getItem('user'))])
    let valueExport={backgroundclr,setBackgroundClr,containerclr,setcontainerclr,navBarClr,setNavClr,topBar,getColor,setTopBar}
    return (
        <Store.Provider value={valueExport}>
            {props.children}
        </Store.Provider>
    )
}

export default Storage
